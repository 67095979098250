
    /deep/ .el-radio__input.is-checked+.el-radio__label {
        color: #606266;
    }
    /deep/ .el-radio__input.is-checked .el-radio__inner {
        background: #1122D8 !important;
        border-color: #1122D8 !important;
    }
    /deep/.pages-center{
        display: flex;
        justify-content: center;
        margin-top: 24px;
        margin-bottom: 20px;
    }
    .creationCompetitionThree{
        .grading_type {
            display: flex;
            align-items: flex-start;
            margin-bottom: 10px;
            .grading_type_title {
                line-height: 18px;
                width: 80px;
                font-size: 16px;
            }
            .grading_type_radio {
                width: 80%;
                /deep/ .el-radio {
                    margin-bottom: 10px;
                }
            }
        }
        display: flex;
        flex-direction: column;
        padding:40px 0 30px 30px;
        .creationCompetitionThree-header{
            display: flex;
            .selectJury{
                min-height: 470px;
                width: 940px;
                border: #EEEEEE 1px solid;
                .selectJuryHeader{
                    height: 60px;
                    background: #F2F3FA;
                    line-height: 60px;
                    padding-left: 30px;
                    border-bottom: 1px solid #EEEEEE;
                    color: #333333;
                }
                .selectJuryContent{
                    /*padding:0 0px 30px 30px;*/
                    padding: 0 20px;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-around;
                    &:after {
                        content: "";
                        flex: auto;
                    }
                    .selectJuryContentSpan:nth-child(1){
                        margin-left: 0;
                    }
                    .selectJuryContentSpan{
                        margin-left: 20px;
                        /*justify-content: space-around;*/
                        text-align: center;
                        .selectRed{
                            color:#409EFF;
                            border-color: #409EFF;
                            background: #ffffff;
                        }
                    }
                    span{
                        /*margin-left:60px;*/
                        display: inline-block;
                        /*border: 1px solid #DCDCDC;*/
                        /*line-height: 38px;*/
                        /*padding: 0 7px;*/
                        background: #ffffff;
                        margin-top: 30px;
                        cursor:pointer;
                        color: #4B5366;
                        border-color: #E9EAEC;
                    }
                }
            }
            .selectedJury{
                margin-left: 30px;
                width: 588px;
                border: #EEEEEE 1px solid;
                .selectedJuryHeader{
                    height: 60px;
                    background: #F2F3FA;
                    line-height: 60px;
                    padding-left: 30px;
                    border-bottom: 1px solid #EEEEEE;
                    color: #333333;
                }
                .selectedJuryContent{
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-around;
                    padding: 0 20px;
                    &:after {
                        content: "";
                        flex: auto;
                    }
                    .selectedJuryContentSpan:nth-child(1){}
                    margin-left: 0;
                    .selectedJuryContentSpan{
                        margin-left: 20px;
                        text-align: center;
                    }
                    span{
                        display: inline-block;
                        margin-top: 30px;
                        cursor:pointer;
                        background: #ffffff;
                        border-color: #409EFF;
                    }
                }
            }
        }
        .upDownBtn{
            margin: 0 auto;
            padding-top: 40px;
        }
    }
